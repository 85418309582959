import React from "react"

const Footer = () => (
  <footer className="footer-1 bg-darker">
    <div className="container">
      <div className="row">
        <div className="col-md-6 col-md-offset-3 col-sm-8 col-sm-offset-2 text-center">
          <a href="/">
            <h4 className="bolder uppercase">heartvslogic</h4>
          </a>
          <ul className="list-inline social-list mb0">
            <li>
              <a href="about.html">BIO</a>
            </li>
            <li>
              <a href="featured.html">FEATURED</a>
            </li>
            <li>
              <a href="contact.html">CONTACT</a>
            </li>
          </ul>
        </div>
      </div>
      {/* <!--end of row--> */}

      <div className="row">
        <div className="col-sm-6">
          <span className="sub">&copy; Copyright 2021 | Heart Vs Logic</span>
        </div>
        <div className="col-sm-6 text-right">
          <ul className="list-inline social-list">
            <li>
              <a href="https://facebook.com/tayooladelet">
                <i className="ti-facebook"></i>
              </a>
            </li>
            <li>
              <a href="mailto:tayoo3@vt.edu">
                <i className="ti-email"></i>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/heartvslogic/">
                <i className="ti-instagram"></i>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    {/* <!--end of container--> */}
    <a className="btn btn-sm fade-half back-to-top inner-link" href="#top">
      Top
    </a>
  </footer>
)

export default Footer
