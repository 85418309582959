import React from "react"
import PropTypes from "prop-types"
import { withPrefix } from "gatsby"
import { Helmet } from "react-helmet"

// Styles
import "../styles/custom.css"
import "../styles/flexslider.css"
import useScript from "../libs/useScripts"
import Footer from "./Footer"
import Header from "./Header"

const Layout = ({ children }) => {
  useScript(withPrefix("js/jquery.min.js"))
  useScript(withPrefix("js/bootstrap.min.js"))
  useScript(withPrefix("js/flexslider.min.js"))
  useScript(withPrefix("js/masonry.min.js"))
  useScript(withPrefix("js/smooth-scroll.min.js"))
  useScript(withPrefix("js/particles.min.js"))
  useScript(withPrefix("js/scripts.js"))
  useScript(withPrefix("js/lazysizes.min.js"))

  return (
    <>
      <Helmet>
        {/* Gist */}
        <link
          href={withPrefix(
            "https://fonts.googleapis.com/css?family=Lato:300,400%7CRaleway:100,400,300,500,600,700%7COpen+Sans:400,500,600"
          )}
          rel="stylesheet"
          type="text/css"
        />

        <link
          href={withPrefix("css/bootstrap.css")}
          rel="stylesheet"
          type="text/css"
        />
        <link
          href={withPrefix("css/themify-icons.css")}
          rel="stylesheet"
          type="text/css"
        />
        <link
          href={withPrefix("css/theme.css")}
          rel="stylesheet"
          type="text/css"
        />
        <link
          href={withPrefix("css/custom.css")}
          rel="stylesheet"
          type="text/css"
        />
      </Helmet>

      <div className="scroll-assist">
        <Header />
        <main>
          {children}
          <Footer />
        </main>
      </div>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
