import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"

const Header = () => {
  const query = useStaticQuery(graphql`
    query {
      allContentfulGallery(filter: { featured: { eq: true } }) {
        nodes {
          title
          slug
        }
      }
    }
  `)

  const {
    allContentfulGallery: { nodes: featuredGalleries },
  } = query
  return (
    <div className="nav-container">
      <div id="top"></div>
      <nav className="absolute transparent">
        <div className="nav-bar">
          <div className="module left">
            <Link to="/">
              <h4 className="uppercase bolder white-text">
                Heart<small>v</small>Logic
              </h4>
            </Link>
          </div>
          <div className="module widget-handle mobile-toggle right visible-sm visible-xs">
            <i className="ti-menu"></i>
          </div>
          <div className="module-group right">
            <div className="module left">
              <ul className="menu">
                <li>
                  <Link to="/">HOME</Link>
                </li>
                <li>
                  <Link to="/blog">BLOG</Link>
                </li>

                <li className={featuredGalleries.length && "has-dropdown"}>
                  <Link to="/featured/">FEATURED WORK</Link>

                  {featuredGalleries.length && (
                    <ul>
                      {featuredGalleries.map(item => {
                        return (
                          <li key={item.slug}>
                            <Link to={`/gallery/${item.slug}`}>
                              {item.title}
                              <hr />
                            </Link>
                          </li>
                        )
                      })}
                    </ul>
                  )}
                </li>
                <li>
                  <Link to="/senior">Senior Portraits</Link>
                </li>
                <li>
                  <Link to="/rates">Rates</Link>
                </li>
                <li>
                  <Link to="/about">About</Link>
                </li>
                <li>
                  <Link to="/contact">Contact</Link>
                </li>
              </ul>
            </div>
            {/* <!--end of menu module--> */}
          </div>
          {/* <!--end of module group--> */}
        </div>
      </nav>
    </div>
  )
}

export default Header
